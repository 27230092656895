import React from "react";
import WoodlandCTA from "./WoodlandCTA";
import Accordion from "./Accordion";
import "./FAQ.css"
import BookNowBtn from "./BookNowBtn";

function FAQs() {

    return (
        <section className="FAQs">
            <div className="container">
                <div className="row">
                    <WoodlandCTA></WoodlandCTA>
                </div>
                <div className="FAQs__meta">
                    <h2>Frequently Asked Questions</h2>
                    <h3 className="text-uppercase">At the magical woodland</h3>
                </div>

                {/* Tickets */}
                <Accordion
                    title={"Tickets"}
                    defaultOpen
                >
                    <div className="FAQ">
                        <p className="FAQ__title">Do I need to buy tickets in advance?</p>
                        <p>We strongly recommend purchasing your tickets before you travel to Blakemere. We envisage the peak shows to sell out.</p>
                    </div>
                    <div className="FAQ">
                        <p className="FAQ__title">Where do I get my tickets from?</p>
                        <p>
                            This is a ticketed event. Tickets are available online
                            <BookNowBtn></BookNowBtn>
                            or call 01606 882223
                        </p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">Can we collect tickets from the box office?</p>
                        <p>No, there is no collect at box office for this event. However if you are unable to access a printer, you can purchase tickets online and have them emailed to you.</p>
                    </div>


                    <div className="FAQ">
                        <p className="FAQ__title">What does my Ticket Time mean?</p>
                        <p>The time on your ticket is the time you will enter Magical Woodland. We recommend you arrive at least 15 minutes prior to show time as the sequences are timed to the show times. Latecomers will be admitted onto the next available show time. </p>
                    </div>



                    <div className="FAQ">
                        <p className="FAQ__title">Will my ticket be posted to me?</p>
                        <p>If you have chosen an e-ticket option only, you will NOT recieve a printed ticket by post. If you have selected a printed ticket your tickets will be posted out to you first class post.<br />

You will also receive a confirmation direct to your email for this purchase. Tickets are dispatched from mid July </p>
                    </div>



                    <div className="FAQ">
                        <p className="FAQ__title">I would like to exchange my tickets, is that possible?</p>
                        <p> Tickets cannot be refunded. Tickets can be exchanged at the sole discretion of the box office manager and subject to availability.</p>
                    </div>



                    <div className="FAQ">
                        <p className="FAQ__title">I’ve lost my ticket, can I get a replacement?</p>
                        <p>If you can’t find your tickets, please contact our box office via their online chat service to arrange for the collection of replacement tickets. You may be required to prove your identity and/or proof of purchase. </p>
                    </div>



                    <div className="FAQ">
                        <p className="FAQ__title">I’ve not received my tickets and the show is tonight, what do I do?</p>
                        <p> If you’ve not received your tickets, contact our box office via their online chat service to arrange a replacement ticket by email. You will be required to show proof of purchase.</p>
                    </div>



                    <div className="FAQ">
                        <p className="FAQ__title">Am I able to exchange my tickets?</p>
                        <p>Tickets cannot be refunded. Tickets can be exchanged at the sole discretion of the box office and subject to availability.  </p>
                    </div>



                    <div className="FAQ">
                        <p className="FAQ__title">What is Magical Woodland?</p>
                        <p>Magical Woodland is an outdoor walking experience – a sound and light show which takes place at the private woodland at Cheshire Outdoors at Blakemere Village. Visitors will be treated to a series of choreographed sound and light shows, along with some interactive features, while they follow the path around the forest. </p>
                    </div>


                </Accordion>

                {/* This show */}
                <Accordion
                    title={"The Show"}

                >
                    <div className="FAQ">
                        <p className="FAQ__title">What is the Magical Woodland Show?</p>
                        <p>A magical outdoor experience with dazzling lighting and supreme sound in the depths of a Cheshire woodland.</p>
                    </div>
                    <div className="FAQ">
                        <p className="FAQ__title">How long does the show last for?</p>
                        <p>It is a walking experience and can last as long you wish it too. <br />
You can walk around multiple times and there will be other facilities and a central food court for all our visitors to enjoy. </p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title"> Are there toilets in the Woodland?</p>
                        <p>Yes, there are toilets at Cheshire Outdoors. </p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title"> Is there food available in the Woodland?</p>
                        <p>
                            Yes, we have catering situated in the centre of the experience offering a selection of hot foods and hot and cold drinks.</p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">Is there Parking on site? </p>
                        <p>Yes, there is ample parking onsite</p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">Can I take a taxi to the Experience? </p>
                        <p> Yes, taxis are able to drop off close to the entrance which is via Cheshire Outdoors.</p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">Can I take a pram/buggy with me as I have small children? </p>
                        <p> Yes, we advise that you bring a sturdy pram/buggy as the terrain is uneven in the woodland paths. They are clear and made from gravel. </p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">Are dogs allowed at Magical Woodland? </p>
                        <p>No, dogs are not allowed at Magical Woodland. The event attracts lots of people and that, combined with very hot lights, electricity cables and loudspeakers situated at curious doggy level can make it too dangerous for our furry four legged friends. </p>
                    </div>
                </Accordion>

                {/* Speical Needs */}
                <Accordion title="Special Needs">

                    <div className="FAQ">
                        <p className="FAQ__title">Is it suitable for children/elderly? </p>
                        <p> The experience has been designed to cater for all ages and abilities, however, care should be taken on paths and they are not suitable for very large buggies. Paths are suitable for some push wheelchairs.</p>
                    </div>
                    <div className="FAQ">
                        <p className="FAQ__title">Is it suitable for disabled visitors? </p>
                        <p>The forest is accessible to some push wheelchair users.  Paths are not suitable for wheelchairs with small wheels or electric wheelchairs. </p>
                    </div>
                    <div className="FAQ">
                        <p className="FAQ__title"> Do you have wheelchairs or mobility scooters on site for public use?</p>
                        <p>No, you will be required to provide your own mobility aids. The site is not accessible by wheelchairs. Mobility scooters are not advised as there are steep inclines and unsuitable terrain and space for these. </p>
                    </div>
                    <div className="FAQ">
                        <p className="FAQ__title">Are Strobe Effects used in the Show? </p>
                        <p>It is not recommended that photosensitive persons attend Magical Woodland. While no single source of lighting regularly strobes at high frequency, the volume of light fixtures on site could raise the frequency of light flashes and strobing across multiple sources to a level where it may be considered unsafe for people who are affected by photo-sensitive epilepsy </p>
                    </div>
                </Accordion>

                {/* Cancellation */}
                <Accordion title="Cancellation">


                    <div className="FAQ">
                        <p className="FAQ__title"> The event will only be cancelled in the following circumstances:</p>
                        <p>
                            When the weather at Blakemere is perceived to be a danger to the public in the forest (e.g extremely high winds) determined by the event organiser – don’t rely on weather apps of your own!
    <br />
	A major power failure on site or with event power
</p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title"> The event will NOT be cancelled in the following circumstances:</p>
                        <p>
                            <ul>
                                <li>Heavy Rain in Sandiway – we advise that all visitors dress appropriately for the weather and bring an umbrella.</li>
                                <li>Heavy Rain in Another Part of Cheshire/England/Wales – we advise our visitors to take note of the weather conditions on route to the event and allow ample time for their journey.  </li>
                                <li>Very Cold Weather – we advise that our visitors dress warmly and appropriately for cold weather</li>
                            </ul>
	The decision to cancel will be made at 12:00hrs on the day of the event. Shortly after we will endeavour to contact you either by e-mail or by text and a notice will be posted on the front page of our website.
You should check the web site from mid-day on the day you are going to visit, particularly if extremely high winds or storm conditions are forecast.
 </p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">If the show time is cancelled can I book another date/show time? </p>
                        <p>If tickets are available then you will be given the option to swap dates. </p>
                    </div>

                    <div className="FAQ">
                        <p className="FAQ__title">If the show time is cancelled do I get a refund? </p>
                        <p>Yes, you will be refunded as per the customer purchase policy with our ticket provider. Your payment details are not held on file so, in the event of a refund, please for us to contact you. </p>
                    </div>

                </Accordion>
            </div>
        </section>
    )
}

export default FAQs;