import React from "react";
import woodlandbg from '../woodlandcta.png';
import logo from '../magwoodlogo.svg';
import BookNowBtn from './BookNowBtn';
import "./WoodlandCTA.css";

function WoodlandCTA(){

    return (
        <div className="WoodlandCTA" style={{
            backgroundImage: `url(${woodlandbg})`}}
        >
            <img src={logo} alt="The Magical Woodland" />
            <BookNowBtn href="https://magicalwoodland.com">Read More</BookNowBtn>
        </div>
    )
}

export default WoodlandCTA