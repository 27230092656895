import React, { useState } from "react";
import "./Accordion.css"

function Accordian( {
        defaultOpen = false,
        title = "",
        children,
    }) {
    const [ isOpen, setIsOpen ] = useState( defaultOpen );
    return (
        <div className={`Accordion ${isOpen ? "open" : ""}`}>
            <div className={`Accordion__tab`} onClick={ () => setIsOpen(! isOpen ) }>
                {title}
            </div>
            <div className={`Accordion__content`}>
                { children }
            </div>
        </div>
    )
}

export default Accordian;