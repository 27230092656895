import React from 'react';
import './App.css';
import TopBanner from './Components/TopBanner';
import About from './Components/About';
import AvailableDates from './Components/AvailableDates';
import WoodlandCTA from './Components/WoodlandCTA';
import Footer from './Components/Footer';
import FAQs from './Components/FAQ';

function App() {
  return (
    <>
      <TopBanner></TopBanner>
      <About></About>
      <AvailableDates></AvailableDates>
      <FAQs>

      </FAQs>
      <Footer></Footer>
    </>
  );
}

export default App;
