import React from 'react';
import BookNowBtn from './BookNowBtn';
import logo from '../magwoodlogo.svg';
import logoAtChristmas from "../christmas_logo_v1.svg";
import "./TopBanner.css";

export default function TopBanner() {
    return (
        <section className="TopBanner">
            
  <svg className="svg-snowscene" xmlns="http://www.w3.org/2000/svg">
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
    <circle className="snow" />
  </svg>
            <div className="container" style={{"margin-top":"-160px"}}>
                <div className="row">
                    <div className="col text-left">
                        <img className="nav-logo" src={logo} ></img>
                    </div>
                    <div className="col text-right">
                        <BookNowBtn></BookNowBtn>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div className="col text-center">
                        <img src={logoAtChristmas} width="50%" className="mt-2"></img>
                        <br />
                        <div className="mt-5">
                            <BookNowBtn></BookNowBtn>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}