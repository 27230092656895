import React from 'react';
import './BookNowBtn.css';

export default function BookNowBtn({ outline = false, children = "BOOK TICKETS", href="https://www.wtm360.co.uk/cheshire-outdoors-1550224632-magical-woodland-at-christmas.html" }){

    return (
        <a 
            target="_blank" 
            href={href}
            className={`book-now-btn btn ${ outline ? "outline" : "" }`}
        >
            { children }
        </a>
    )
}