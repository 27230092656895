import React from 'react';
import "./AvailableDates.css";
import BookNowBtn from './BookNowBtn';

export default function AvailableDates() {

    let datearray = [
        "28th Nov",
        "29th Nov",
        "30th Nov",
        "31st Nov",
        "1st Dec",
        "2nd Dec",
        "3rd Dec",
        "4th Dec",
        "5th Dec",
        "6th Dec",
        "7th Dec",
        "8th Dec",
        "9th Dec",
        "10th Dec",
        "11th Dec",
        "12th Dec",
        "13th Dec",
        "14th Dec",
        "15th Dec",
        "16th Dec",
        "17th Dec",
        "18th Dec",
        "19th Dec",
        "20th Dec",
        "21st Dec",
        "22nd Dec",
        "23rd Dec",
        "24th Dec",
        "25th Dec",
        "26th Dec",
        "27th Dec",
        "28th Dec",
        "29th Dec",
        "30th Dec",
        "31st Dec",
    ];

    return (
        <section id="availabledates">
            <div className="container">
                <h1>Available Dates</h1>
                <h2>November to December</h2>

                <div className="row justify-content-center justify-content-sm-start calendar">
                    {datearray.map((d, i) =>
                        <div className="col-10 col-sm-6 col-lg-3 p-2">
                            <div className="option">
                                <h1>{d}</h1>
                                {/* <h2>Open XPM - YPM</h2> */}
                                <p className="price">
                                    From £13.75 pp (with Family ticket)
                                </p>
                                <p className="">
                                    <BookNowBtn outline >Check Availability</BookNowBtn>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )

}