import React from 'react';
import "./About.css";
import gtg from '../goodtogo.svg';

export default function About() {
    return (
        <section id="about">
            <div className="text-center">
                <h1>Christmas</h1>
                <h2>at the magical woodland</h2>
                <p>
                    With the woodland as a natural backdrop, you will experience a truly magical christmas experience. This is a walking experience where you will embark on a journey through a 15 acre magical woodland.

                    </p>
                    
                    <img className="text-center" src={gtg} alt="We're good to go"></img>
                <p><small>
                    We advise you dress for the weather. Each type of weather brings a whole different show with each element adding to the experience. Please note that this is a walking experience along a woodland floor path – this surface maybe uneven and there are some steep inclines en route.
                    </small></p>
                <p>
                    Hot drinks and refreshments are available onsite. Free Car Parking available – for any general enquiries please call us on <a href="tel:01606 882223">01606 882223</a>

                </p>
                <p>
                    Ticket prices start at <br />
                    £13.75 pp <small>when buying a family ticket for 4 people.  </small><br />
                    Adults £17.95<br />
                    Children £14.50 <small>with Under 2's free.</small>
                </p>
            </div>
        </section >
    )
}