import React from "react";
import logo from '../footerlogo.svg';
import gtg from '../goodtogo.svg';
import visa from '../visa.png';
import mc from '../mc.png';
import amex from '../amex.png';
import paypal from '../paypal.png';
import fb from '../fb.svg';
import twitter from '../twitter.svg';
import "./Footer.css";
function Footer({

}) {

    return (
        <footer className="footer">
            <div className="footer__top">
                <img src={logo} alt="The Magical Woodland Logo" />
                <div className="Footer__socials">
                    <a href="https://facebook.com">
                        <img src={fb}></img>
                    </a>
                    <a href="https://twitter.com">
                        <img src={twitter}></img>
                    </a>
                </div>
            </div>
            <div>
                <hr />
                <div className="footer__flex">
                    <div>
                        <p>&copy; 2020 All rights reserved. By Code and Create</p>
                        <p>
                            <img src={visa} alt="VISA" />
                            <img src={amex} alt="American Express" />
                            <img src={mc} alt="MasterCard" />
                            <img src={paypal} alt="PayPal" />
                        </p>
                        <p>Company Number 09135380, Reg United Kingdom, Forest Explorers Ltd, C/O Bowyer Accountants, Portal Business Centre, Dallam Ln, Warrington, WA2 7LT .</p>
                    </div>
                    <div class="text-center">
                        <img src={gtg} alt="We're good to go"></img>
                    </div>
                    <div className="text-right">
                        <address>
                            Cheshire Outdoors,<br />
                            Blakemere Village,<br />
                            Sandiway,<br />
                            Cheshire CW8 2EB<br />
                            <a href="tel:01606882223">Tel: 01606 882223</a>
                        </address>
                        <p>
                            <a target="_blank" href="https://www.magicalwoodland.com/privacy-policy/">Privacy Policy</a> |
                            <a target="_blank" href="https://www.magicalwoodland.com/Magical%20Woodland%20Covid-19.pdf">COVID-19 Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;